import React from 'react'
import Navbar from './comp/nav_bar'
import Footer from './comp/footer'
import { Outlet } from 'react-router-dom'

function Layout() {
  return (
    <div className='dark:bg-gray-900 dark:text-white'>
      <Navbar/>
      <Outlet/>
      <Footer/>
    </div>
  )
}

export default Layout;