import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import Icon from "../icon.png";
import { Link, NavLink } from "react-router-dom";


const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className=" bg-white dark:bg-slate-900 flex justify-between items-center h-16  mx-auto px-4 text-slate-300 dark:text-white">
      <div className="flex items-center">
        <img className="App-logo h-10 w-10" alt="logo" src={Icon} />
        <h1 className="ms-2 font-semibold text-lg">Coading India</h1>
      </div>

      <ul className="hidden md:flex">
        <li className="m-2 p-2">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? " text-blue-500 font-bold" : " text-slate-200"} `
            }
          >
            Home
          </NavLink>
        </li>
        <li className="m-2 p-2">
        <NavLink
            to="/contact"
            className={({ isActive }) =>
              `${isActive ? " text-blue-500 font-bold" : " text-slate-200"} `
            }
          >
            Contact
          </NavLink>
        </li>


      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        onClick={handleNav}
        className={
          nav
            ? "fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white dark:bg-slate-900 ease-in-out duration-500 text-slate-300 dark:text-white"
            : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]"
        }
      >
        <img className="App-logo h-10 w-10" alt="logo" src={Icon} />

        <li className="p-4 rounded-xl hover:bg-[#66c6df] duration-300 hover:text-[#66c6df] cursor-pointer border-[#208b3a]">
          <NavLink
            to="/"
            className={({ isActive }) =>
              `${isActive ? "text-blue-500 font-bold" : " text-slate-200"} `
            }
          >
            Home
          </NavLink>
        </li>

        <li className="p-4 rounded-xl hover:bg-[#66c6df] duration-300 hover:text-[#66c6df] cursor-pointer border-[#208b3a]">
        <NavLink
            to="/contact"
            className={({ isActive }) =>
              `${isActive ? " text-blue-500 font-bold" : " text-slate-200"} `
            }
          >
            Contact
          </NavLink>
        </li>


        {/* <li className="p-4 rounded-xl hover:bg-[#7ae582] duration-300 hover:text-[#208b3a] cursor-pointer border-[#208b3a]">
          Login
        </li>

        <li className="p-4 rounded-xl hover:bg-[#7ae582] duration-300 hover:text-[#208b3a] cursor-pointer border-[#208b3a]">
          Profile
        </li> */}
      </ul>
    </div>
  );
};

export default Navbar;
