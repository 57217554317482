

function Service({icon, text}) {
  return (
    <div class="m-2 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-10 flex flex-col justify-items-center w-full lg:h-64 lg:w-64">
      <img src={icon} className="lg:h-28 lg:w-28 self-center " />
      <h1 className=" lg:text-lg text-white text-center">{text}</h1>
    </div>
  );
}

export default Service;
