import Icon from "../icon.png";
import Service from "./service";
import ProjectCard from "./project_card";
import VideoCard from "./video_card";

import android from "../res/android.png";
import web_dev from "../res/web_dev.png";
import react from "../res/react.png";
import database from "../res/database.png";
import sql from "../res/sql.png"
import mongodb from "../res/mongodb.png"

import sampatti_satna from "../res/project images/sampatti_satna.png"
import easy_busizz from "../res/project images/eazy_buse.png"
import sendoc from "../res/project images/sendoc.png"

function Home() {
  return (
    <div className="dark:bg-gray-900 dark:text-white">
      <div className="flex flex-row p-10 w-full h-max ">
        <div className="w-1/2 flex justify-center flex-col">
          <h1 className="text-4xl font-bold text-slate-600 md:text-7xl">
            Welcome to
          </h1>
          <h1 className="text-4xl font-bold  text-slate-600 md:text-7xl">
            Coding India World
          </h1>
          <h1 className="text-xl font-light text-gray-500">
            Empowering Innovation through Android & Web Solutions
          </h1>
          <a
            href="https://firebasestorage.googleapis.com/v0/b/coding-india.appspot.com/o/resume%2FSachin's%20Resume.pdf?alt=media&token=b9e91694-dacd-47d8-b12c-b1b926ef36c2"
            target="_blank"
          ></a>
        </div>

        <div className="w-1/2 invisible md:visible md:justify-end">
          <img src={Icon} />
        </div>
      </div>

      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

      <div className="p-2 w-full md:p-10">
        <div className="p-2 flex flex-col md:flex-row md:justify-center">
          <img src={Icon} className=" h-60 w-60 border-2 border-gray-700" />
          <div className="flex flex-col ms-2">
            <h1 className="text-3xl font-bold">Owner Sachin Vishwakarma</h1>
            <h1 className="text-xl">Android/Web Development</h1>
            <p className="mt-2">
              As the owner of Coding India, I specialize in crafting tailored
              Android applications and dynamic websites that drive digital
              success. With a deep expertise in mobile and web development, I’m
              dedicated to delivering innovative, user-centric solutions that
              help businesses thrive in the digital world. From concept to
              launch, I ensure high-quality, scalable, and efficient products
              that meet the unique needs of each client.
            </p>

            <a
              href="https://firebasestorage.googleapis.com/v0/b/coding-india.appspot.com/o/resume%2FSachin's%20Resume.pdf?alt=media&token=8ba03e62-f50c-4fe4-b8ef-b5511a07a82f"
              target="_blank"
            >
            </a>
          </div>
        </div>
      </div>

      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

      <h1 className="text-center text-base font-bold">Service</h1>
      <h1 className="text-center text-2xl font-semibold">What I Do</h1>
      <p className="mt-2 text-center">
        I provide many services like creating web apps, Android apps, hosting
        them and publishing them in Play Store.
      </p>

      <div className="flex flex-col items-center mt-3 md:flex-row md:justify-center p-2 justify-center space-x-* flex-wrap">
        <Service icon={android} text={"Android App development"} />
        <Service icon={web_dev} text={"Website development"} />
        <Service icon={react} text={"React app development"} />
        <Service icon={database} text={"Firebase Database"} />
        <Service icon={sql} text={"My SQL Database"} />
        <Service icon={mongodb} text={"Mongo DB Database"} />
      </div>

      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

      <h1 className="text-center text-base font-bold">Portfolio</h1>
      <h1 className="text-center text-2xl font-semibold">
        Check My Wonderful Works
      </h1>
      <p className="mt-2 text-center">
        Here below are photos and icons of some of my left projects
      </p>

      <div className="flex p-2 flex-row md:p-10 flex-wrap justify-center space-x-*">
        <ProjectCard
          icon={sampatti_satna}
          title={"Sampatti Satna"}
          description={
            "A property selling android app."
          }
        />
        <ProjectCard
          icon={sendoc}
          title={"Sendoc"}
          description={"Document sharing app for school or collage"}
        />
        <ProjectCard
          icon={easy_busizz}
          title={"Easy buizz"}
          description={"Busniss menagement application"}
        />
      </div>

      <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>

      <h1 className="text-center text-base font-bold">You Tube</h1>
      <h1 className="text-center text-2xl font-semibold">
        Check My video in You Tube
      </h1>
      <p className="mt-2 text-center">
        Here below are some videos in my youtube channel
      </p>

      <div className="flex p-2 flex-col md:p-10 md:flex-row md:flex-wrap justify-evenly">
        <VideoCard
          image={
            "	https://i.ytimg.com/vi/zQfj32NFYjQ/hqdefault.jpg?s…AFwAcABBg==&rs=AOn4CLDVb31Kid_hu4_jJTvhw1jnA9tWjQ"
          }
          title={"How to Compress Video Size in Android Using Kotlin"}
          link={"https://youtu.be/zQfj32NFYjQ?si=CZfAmJJY5TkEDoRt"}
        />

        <VideoCard
          image={
            "https://i.ytimg.com/vi/b9AgikEW14U/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLCVAdbGgL4or3DmtWPJB-PLNs0_LA"
          }
          title={"How to compress size image || in [ ANDROID STUDIO ]"}
          link={"https://youtu.be/b9AgikEW14U?si=ylkyz1BAunkfLtWc"}
        />

        <VideoCard
          image={
            "https://i.ytimg.com/vi/9n1wiBPqa0o/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAohSzrL2ylPsZoP9cSn1XTvm91-A"
          }
          title={
            "How to compress image size and upload in firebase storage || [ IN ANDROID STUDIO ]"
          }
          link={"https://youtu.be/9n1wiBPqa0o?si=rJeOLmeraNiYzBb5"}
        />

        <VideoCard
          image={
            "https://i.ytimg.com/vi/s901ONGT1RI/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLBxOEIFnZpZbkFq3iwVoKN7WmGm2w"
          }
          title={
            "Receiving simple data from other apps || [ IN ANDROID STUDIO ]"
          }
          link={"https://youtu.be/s901ONGT1RI?si=QrNBAoDqjTNTkkDc"}
        />

        <VideoCard
          image={
            "https://i.ytimg.com/vi/QoZwWYSJv4Q/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLAg7l99Goq53ERntc9JGcyUvSi0hw"
          }
          title={"How to create notification || Android 13"}
          link={"https://youtu.be/QoZwWYSJv4Q?si=7l7xcGTt0TvP378H"}
        />

        <VideoCard
          image={
            "https://i.ytimg.com/vi/gv2lF6YXYfg/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDPFWMqu3aB5Se4IMNJkjIZ0Y6rsg"
          }
          title={
            "Chat App Part - 1 | Setup Project | Connect Our Project With Firebase | Create Splash Screen"
          }
          link={"https://youtu.be/gv2lF6YXYfg?si=cCi-cYLO-E6HK6wS"}
        />
      </div>
    </div>
  );
}

export default Home;
