
function VideoCard({ image, title, link }) {
  return (
    <a href={link} target="_blank">
      <div className="flex flex-col p-2">
        <img className=" h-56 w-96 rounded-xl" src={image} />
        <h1 className=" text-base font-bold w-96">{title}</h1>
      </div>
    </a>
  );
}

export default VideoCard;
