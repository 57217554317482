import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCJRV51LA_gG0YhbzBWFwOz-EjGVRpNJkM",
  authDomain: "coding-india.firebaseapp.com",
  databaseURL: "https://coding-india-default-rtdb.firebaseio.com",
  projectId: "coding-india",
  storageBucket: "coding-india.appspot.com",
  messagingSenderId: "929017177618",
  appId: "1:929017177618:web:4a591350d6ca2d59641acb",
  measurementId: "G-BKVMQ80D5L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;