function ProjectCard({ icon, title, description }) {
  return (
    <div className="flex flex-col p-10">
      <img src={icon} className="h-96 border-2 p-3 border-gray-700" />
      <div className="flex flex-col ms-2">
        <h1 className="text-xl font-bold max-w-52">{title}</h1>
        <p className=" text-sm font-normal max-w-52">{description}</p>
      </div>
    </div>
  );
}

export default ProjectCard;
